import axiosPost from "./axiosPost";

export const MerchantBOIBUpd= async (credentials, data, location) => {
    const body = {
        service: "MerchantBOIBUpd",
        params: {
            merchantId: data.merchantId,
            merchantIBCod: data.merchantIBCod,
            merchantIBNumero: data.merchantIBNumero,
            merchantProviciaId: data.merchantProviciaId,
            estadoRegistroCod: data.estadoRegistroCod,
            registroLatitud: location.latitud,
            registroLongitud: location.longitud,
        },
    };    
    try {
        
        const response = await axiosPost("Y", body, credentials)
        return response;
    } catch (err) {
        console.error(`Error: ${err}`);
    }
}