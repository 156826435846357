import React, { useEffect, useState } from 'react'
import { useStyles } from './styles';
import { useFormik } from 'formik';
import * as yup from "yup";
import { Box, Grid } from '@mui/material';
import { FormikRadioGroup } from 'components/UI/Inputs/FormikInputs';
import { FormikSelectInput, FormikTextInput } from 'components/Filters/FormikInputs';
import { Button } from 'components';
import { MerchantBOFilesDo } from 'services/hooli-services/MerchantBoFilesDo';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { userCredentials } from 'recoilState/GlobalState';
import { DropZone } from 'components/DropZone';
import { DocumentosPut } from 'services/hooli-services/DocumentosPut';
import { PaisGet } from 'services/hooli-services/PaisGet';
import { MerchantBOLAFTUpd } from 'services/hooli-services/MerchantBOLAFTUpd';

export const LavadoDeDinero = ({ merchantInfo, load, setLoad, setModalDetails, modalDetails, location }) => {
    const styles = useStyles();
    const credentials = useRecoilValue(userCredentials);
    const [updFile, setUpdFile] = useState(false);
    const [file, setFile] = useState(null)
    const [paises, setPaises] = useState([])

    const handleReadFile = async () => {
        const data = {
            fileName: `${merchantInfo.merchantCUIT.replaceAll('-', '')}SO.pdf`,
            code: "SO",
            cuit: merchantInfo.merchantCUIT.replaceAll('-', ''),
            action: "R"
        }
        const response = await MerchantBOFilesDo(credentials, data)
        if (response.status.code === 1 && response.result[0].url) {
            const { data } = await axios.get(response.result[0].url, {
                headers: {
                    'Accept': 'application/pdf',
                    "Content-Type": "application/octet-stream"
                },
                responseType: "blob",
                data: {}
            })
            let urlBlob = window.URL.createObjectURL(new Blob([data]));
            let a = document.createElement('a');
            a.href = urlBlob;
            a.download = `${merchantInfo.merchantCUIT.replaceAll('-', '')}SO.pdf`; // Nombre del archivo
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }

    const handleUpdFile = async () => {
        const data = {
            fileName: `${merchantInfo.merchantCUIT.replaceAll('-', '')}_SO.pdf`,
            code: "SO",
            cuit: merchantInfo.merchantCUIT.replaceAll('-', ''),
            action: "U"
        }
        const response = await MerchantBOFilesDo(credentials, data)
        if (response.status.code === 1) {
            // console.log("File updated", file[0])
            await DocumentosPut(response.result[0].url, file[0])
            setUpdFile(false)
            setFile(null)
        }
    }
    const handleUpd = async (values) => {
        setLoad(true)
        const response = await MerchantBOLAFTUpd(credentials, {
            merchantId: merchantInfo.merchantId,
            merchantSO: values.sujetoObligado ? "Y" : "N",
            merchantNoResidente: values.inversorNoResidenteFiscal ? "Y" : "N",
            merchantPEP: values.politicamenteExpuesto ? "Y" : "N",
            paisId: values.paisResidencia === 0 ? null : values.paisResidencia,
            merchantNoResidenteTC: values.tinNif,
            merchantNoResidenteCuenta: values.numeroTinNif,
            estadoRegistroCod: "A"
        }, location)
        setLoad(false)
        setModalDetails({ ...modalDetails, isOpen: false })
    }
    const handleDecline = async () => {
        setLoad(true)
        const response = await MerchantBOLAFTUpd(credentials, {
            merchantId: merchantInfo.merchantId,
            merchantSO: formik.values.sujetoObligado ? "Y" : "N",
            merchantNoResidente: formik.values.inversorNoResidenteFiscal ? "Y" : "N",
            merchantPEP: formik.values.politicamenteExpuesto ? "Y" : "N",
            paisId: formik.values.paisResidencia === 0 ? null : formik.values.paisResidencia,
            merchantNoResidenteTC: formik.values.tinNif,
            merchantNoResidenteCuenta: formik.values.numeroTinNif,
            estadoRegistroCod: "R"
        }, location)
        setLoad(false)
        setModalDetails({ ...modalDetails, isOpen: false })
    }
    const validationSchema = yup.object({
        sujetoObligado: yup.boolean().required("Debes seleccionar una opción"),
        politicamenteExpuesto: yup.boolean().required("Debes seleccionar una opción"),
        inversorNoResidenteFiscal: yup.boolean().required("Debes seleccionar una opción"),
        paisResidencia: yup.number().when("inversorNoResidenteFiscal", {
            is: true,
            then: yup.number().required("Debes ingresar un pais")
        }),
        tinNif: yup.string().nullable(true).when("inversorNoResidenteFiscal", {
            is: true,
            then: yup.string().required("Debes ingresar un TIN/NIF")
        }),
        numeroTinNif: yup.number().nullable(true).when("inversorNoResidenteFiscal", {
            is: true,
            then: yup.number().min(1000, "Debe ser mayor a 4 digitos").required("Debes ingresar un número")
        }),

    })
    const formik = useFormik({
        initialValues: {
            sujetoObligado: merchantInfo.merchantSO === "Y" ? true : false,
            politicamenteExpuesto: merchantInfo.merchantPEP === "Y" ? true : false,
            inversorNoResidenteFiscal: merchantInfo.merchantNoResidente === "Y" ? true : false,
            paisResidencia: merchantInfo.merchantPaisId ? merchantInfo.merchantPaisId : 0,
            tinNif: merchantInfo.merchantNoResidenteTipoCuenta,
            numeroTinNif: merchantInfo.merchantNoResidenteCuenta
        },
        onSubmit: (values) => {
            handleUpd(values)
        },
        validationSchema: validationSchema,
        initialErrors: {
            politicamenteExpuesto: false,
            sujetoObligado: false,
            inversorNoResidenteFiscal: false,
            paisResidencia: false,
            tinNif: false,
            numeroTinNif: false,
        }
    })
    const dataFormik = {
        sujetoObligado: { label: "Sujeto obligado*", info: "Indicar si se encuentra incluido dentro de la Nómina de Sujetos Obligados a informar a la Unidad de Información Financiera (UIF) establecidos en el artículo 20 de la Ley 25.246, modificatorias y complementarias." },
        politicamenteExpuesto: { label: "Politicamente expuesto*", info: "Personas humanas que cumplan funciones públicas de relevancia, incluyendo a las personas por cercanía, consanguinidad, o afinidad según la resolución UIF 35/2023 y modificatorias." },
        inversorNoResidenteFiscal: { label: "Inversor no residente fiscal*", info: "Comprende a aquellas Personas Humanas, Personas Jurídicas y Beneficiaros Finales con residencia fiscal en el exterior según lo establecido la RG 4056-E AFIP, modificatorias y/o complementarias" },
        paisResidencia: { label: "País de residencia" },
        tinNif: { label: "TIN/NIF", options: [{ nombre: "TIN", value: "T" }, { nombre: "NIF", value: "N" }] },
        numeroTinNif: { label: `Número de ${formik.values.tinNif === "T" || formik.values.tinNif === null ? "TIN" : "NIF"}`, }
    }
    const getPaises = async () => {
        const response = await PaisGet(credentials)
        if (response.status.code === 1) {
            setPaises(response.result)
        }
    }
    useEffect(() => {
        getPaises()
    }, [])
    return (
        <>
            {updFile ? <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                <DropZone file={file} setFile={setFile} />
                <Grid item md={4} lg={4} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                    <Button text={"Subir"} onClick={handleUpdFile} />
                    <Button text={"Cancelar"} onClick={() => setUpdFile(false)} />
                </Grid>
            </div> : <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
                <Grid container width={"100%"} padding={"20px 0 50px 0"}>
                    <Grid item width={"100%"} display={"flex"} gap={4}>
                        <Box display={"flex"} flexDirection={"column"} width={"50%"} className={`${styles.containerInput} ${styles.containerRadio}`}>
                            <FormikRadioGroup name={"sujetoObligado"} info={dataFormik.sujetoObligado.info} formik={formik} label={dataFormik.sujetoObligado.label} />
                        </Box>
                        <Box display={"flex"} flexDirection={"column"} width={"50%"} className={`${styles.containerInput} ${styles.containerRadio}`}>
                            <FormikRadioGroup name={"politicamenteExpuesto"} info={dataFormik.politicamenteExpuesto.info} formik={formik} label={dataFormik.politicamenteExpuesto.label} />
                        </Box>
                    </Grid>
                    <Grid item width={"100%"} display={"flex"} gap={4}>
                        <Box display={"flex"} flexDirection={"column"} width={"50%"} className={`${styles.containerInput} ${styles.containerRadio}`}>
                            <FormikRadioGroup name={"inversorNoResidenteFiscal"} info={dataFormik.inversorNoResidenteFiscal.info} right formik={formik} label={dataFormik.inversorNoResidenteFiscal.label} />
                        </Box>
                        <Box display={"flex"} flexDirection={"column"} width={"50%"} className={styles.containerInput}>
                            <FormikSelectInput name={"paisResidencia"} formik={formik} data={dataFormik} disabled={formik.values.inversorNoResidenteFiscal === "" || formik.values.inversorNoResidenteFiscal === false} options={paises} optionName={"paisNombre"} optionValue={"paisId"} optionKey={"paisId"} noSelect />
                        </Box>
                    </Grid>
                    <Grid item width={"100%"} display={"flex"} gap={4}>
                        <Box display={"flex"} flexDirection={"column"} width={"50%"} className={styles.containerInput}>
                            <FormikSelectInput name={"tinNif"} formik={formik} data={dataFormik} options={dataFormik.tinNif.options} label={dataFormik.tinNif.label} optionName={"nombre"} optionValue={"value"} optionKey={"value"} disabled={formik.values.inversorNoResidenteFiscal === "" || formik.values.inversorNoResidenteFiscal === false} />
                        </Box>
                        <Box display={"flex"} flexDirection={"column"} width={"50%"} className={styles.containerInput}>
                            <FormikTextInput name={"numeroTinNif"} formik={formik} data={dataFormik} disabled={formik.values.inversorNoResidenteFiscal === "" || formik.values.inversorNoResidenteFiscal === false} />
                        </Box>
                    </Grid>
                </Grid>
                <Grid item md={4} lg={4} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                    <Button text={"Descargar constancia SO"} onClick={handleReadFile} />
                    <Button text={"Subir constancia SO"} onClick={() => setUpdFile(true)} />
                </Grid>
            </div>}
            <div>
                <Button text={"Confirmar"} onClick={() => formik.handleSubmit()} disabled={load} />
                <Button text={"Rechazar"} onClick={() => handleDecline()} disabled={load} />
                <Button text={"Cerrar"} onClick={() => setModalDetails({ ...modalDetails, confirmationModalOpen: true })} />
            </div>
        </>
    )
}
