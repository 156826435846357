import axios from "axios";
import { RequestServiceGet } from "./services-mia/RequestServiceGet";
import { serviceURLHooli } from "consts/ServiceURL";
export const getUserCredentials = async () => {
    let userCredentials;
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: {
            service: "TemporalSession",
            params: {},
        },
    };
    try {
        userCredentials = await axios.post(
            serviceURLHooli,
            requestOptions.body,
            requestOptions.headers
        );
    } catch (err) {
        console.log(`Error: ${err}`);
    }
    if (!userCredentials.data || !(userCredentials.data.result?.length > 0)) {
        console.log(`Fetch failed`);
    }
    sessionStorage.setItem(
        "hooliToken",
        userCredentials.data.result[0].hooliToken
    );

    sessionStorage.setItem("sessionId", userCredentials.data.result[0].sessionId);
    sessionStorage.setItem("merchantId", 132);
    sessionStorage.setItem("merchantRZ", "Moov");
    sessionStorage.setItem("merchantNombre", "Moov");
    sessionStorage.setItem("merchantCUIT", "30-71512361-2");

    const newCredentials = {
        ...userCredentials.data.result[0],
        merchantId: 132,
        merchantRZ: "Moov",
        merchantNombre: "Moov",
        merchantCUIT: "30-71512361-2",
    };
    return newCredentials;
};
