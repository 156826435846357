import React from "react";
import {
    FormikDateInput,
    FormikSelectInput,
    FormikTextInput,
} from "./FormikInputs";
import { FiltrosStyles } from "pages/Filtros.styles";
import { Button } from "components";

export default function Filters({ formik, inputsRender }) {
    const styles = FiltrosStyles();

    const handleKeyDown = (e, name) => {
        if (e.key === 'Backspace' || e.key === 'Delete') {
            formik.setFieldValue(name, ''); // Vacía el campo cuando se presiona Backspace o Delete
        }
    };

    return (
        <form className={styles.filtros} onSubmit={formik.handleSubmit}>
            {inputsRender.map((input, index) => (
                <div key={index} className={styles.inputContainer}>
                    {input.type.toLowerCase() === "text" && (
                        <FormikTextInput
                            data={{
                                [input.name]: {
                                    ...input
                                }
                            }}
                            name={input.name}
                            formik={formik}
                            disabled={input.disabled}
                            className={styles.input}
                            onKeyDown={(e) => handleKeyDown(e, input.name)}
                        />
                    )}
                    {input.type.toLowerCase() === "select" && (
                        <FormikSelectInput
                            data={{
                                [input.name]: {
                                    ...input
                                }
                            }}
                            name={input.name}
                            formik={formik}
                            disabled={input.disabled}
                            options={input.options}
                            optionKey={input.firstOptionKey}
                            optionValue={input.firstOptionValue}
                            optionName={input.firstOptionName}
                            className={styles.input}
                        />
                    )}
                    {input.type.toLowerCase() === "date" && (
                        <FormikDateInput
                            type="date"
                            variant="standard"
                            data={{
                                [input.name]: {
                                    ...input
                                }
                            }}
                            name={input.name}
                            formik={formik}
                            disabled={input.disabled}
                            className={styles.input}
                        />
                    )}
                </div>
            ))}
            <Button type="submit" text="Filtrar" className={styles.button} />
        </form>
    );
};